import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IS_SOCIAL_DISPLAYED } from 'src/app/shared/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  content:any;
  iS_SOCIAL_DISPLAYED = IS_SOCIAL_DISPLAYED;

  constructor(public translate: TranslateService) {
    console.log('Footer constructor called');
  }

  ngOnInit() {
    this.setContent();
  }

  setContent(){
    this.content = this.translate.instant('Footer');
  }

}
