import { Component, Input, OnInit } from '@angular/core';
import { SliderArrow } from '../../constants';

@Component({
  selector: 'pls-slider-container',
  templateUrl: './pls-slider-container.component.html',
  styleUrls: ['./pls-slider-container.component.scss']
})
export class PlsSliderContainerComponent implements OnInit {


  itemActive = 'slider__item--active';
  bulletActive = 'slider__bullet-item--active';
  bulletActiveIndex = 0;
  @Input() boxHeight = '';
  @Input() class = '';
  @Input() sliderArrow: SliderArrow[] = [];

  constructor() {
  }

  ngOnInit(): void {
  }

  sliderNext(index: number) {
    const maxIndex = this.sliderArrow.length - 1;
    this.bulletActiveIndex = index > maxIndex ? 0 : index < 0 ? maxIndex : index;
  }
}
