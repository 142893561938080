import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  content:any;

  constructor(
    public translate: TranslateService,
    public commonService: CommonService
  ) { }

  ngOnInit() { 
    this.setContent();
  }

  setContent(){
    this.content = this.translate.instant('Header');
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.commonService.setLanguage(lang);
    this.setContent();
  }
  openMenu(isOpen: boolean) {
    let navLinks = document.querySelector('.nav-links');
    if (isOpen) {
      (navLinks as HTMLElement)!.style.left = '0';
    } else {
      (navLinks as HTMLElement)!.style.left = '-100%';
    }
  }
  openSubMenuLevel1(itemName: string) {
    let item = document.getElementById(itemName);
    (item as HTMLElement)!.classList.toggle('show1');
    let itemArrow = document.getElementById("i_" + itemName);
    (itemArrow as HTMLElement)!.classList.toggle('rotate180');
  }

  openSubMenuLevel2(itemName: string) {
    let item = document.getElementById(itemName);
    (item as HTMLElement)!.classList.toggle('show2');
    let itemArrow = document.getElementById("i_" + itemName);
    (itemArrow as HTMLElement)!.classList.toggle('rotate90');
  }
}
