<ul>
    <li *ngIf="writeToMe"><a [href]="writeToMeUrl">
            <i class="bi bi-envelope" aria-hidden="true"></i>{{writeToMe}}</a>
    </li>
    <li *ngIf="twitter"><a [href]="twitterUrl">
            <i class="bi bi-twitter" aria-hidden="true"></i>{{twitter}}</a>
    </li>
    <li *ngIf="linkedIn"><a [href]="linkedInUrl">
            <i class="bi bi-linkedin" aria-hidden="true"></i>{{linkedIn}}</a>
    </li>
</ul>
