<div class="row justify-content-center" [ngClass]="class">
    <div class="slider__arrow col-1 hidden-md-down">
        <button class="slider__btn slider__btn--prev" (click)="sliderNext(bulletActiveIndex - 1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 10 15">
                <title>Left arrow</title>
                <path fill="none" fill-rule="evenodd" stroke="#2b0a3d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" d="M7 1l-6.5 6.5 6.5 6.5"></path>
            </svg>
        </button>
    </div>
    <div class="col-10 offset-sm-1 offset-md-0">
        <div class="slider">
            <div class="slider__swipe-window">
                <div class="row slider-mobile slider-mobile--center slider__swipe-list" data-is-desktop="1">
                    <div class="slider__item col-12" *ngFor="let s of sliderArrow; let i = index"
                        [ngClass]="bulletActiveIndex===i? itemActive : ''">
                        <pls-box-container [url]="s.url" [isMore]="s.isMore" [title]="s.title"
                            [subHeading]="s.subHeading" [bodyText]="s.bodyText" [class]="s.class"
                            [imgSrc1]="s.imgSrc1" [imgSrc2]="s.imgSrc2" [boxHeight]="s.boxHeight">
                        </pls-box-container>
                    </div>
                </div>
            </div>
            <nav class="slider__bullets">
                <button type="button" *ngFor="let s of sliderArrow; let i = index" (click)="sliderNext(i)"
                    [ngClass]="bulletActiveIndex===i? bulletActive : ''" class="slider__bullet-item"
                    data-index="i">
                    <span class="sr-only">Go to slide {{i + 1}}</span>
                </button>
            </nav>
        </div>
    </div>
    <div class="slider__arrow col-1 hidden-md-down">
        <button class="slider__btn slider__btn--next" (click)="sliderNext(bulletActiveIndex + 1)">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="28" viewBox="0 0 10 15">
                <title>Right arrow</title>
                <path fill="none" fill-rule="evenodd" stroke="#2b0a3d" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" d="M1.455 1l7 6.5-7 6.5"></path>
            </svg>
        </button>
    </div>
</div>