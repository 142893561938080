import { Component, OnInit } from '@angular/core';
import { IS_SOCIAL_DISPLAYED } from '../../constants';

@Component({
  selector: 'pls-scroll-social',
  templateUrl: './pls-scroll-social.component.html',
  styleUrls: ['./pls-scroll-social.component.scss']
})
export class PlsScrollSocialComponent implements OnInit {
  
  iS_SOCIAL_DISPLAYED = IS_SOCIAL_DISPLAYED;

  constructor() { }

  ngOnInit(): void {
  }

}
