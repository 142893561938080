import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[plScroll]'
})
export class ScrollDirective {

  @Input() plScroll = 0;
  topRectPos = 0;

  constructor(private el: ElementRef) { }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    if (this.plScroll == 0) {
      this.setFixedNavButtons();
    } else if (this.plScroll == 1) {
      this.setFixedSocialButtons();
    }
  }

  setFixedNavButtons() {
    let scrolltop = document.documentElement.scrollTop | document.body.scrollTop;

    if (scrolltop >= 495.962) {
      this.el.nativeElement.classList.add('menu--fixed');
    }
    else {
      this.el.nativeElement.classList.remove('menu--fixed');
    }
  }

  setFixedSocialButtons() {
    let socialButtonsDiv = this.el.nativeElement;
    if (socialButtonsDiv != null) {
      let socialButtons = this.el.nativeElement.querySelector('.scroll-social');
      if(socialButtons !=null){
        let topSection = document.querySelector('.hero_default__pic');     
      
        let topRect: DOMRect;
        let topRectPos = 100;
        if (topSection != null) {
          topRect = topSection.getBoundingClientRect();
          topRectPos = topRect.height + 250
        }
        const rect: DOMRect = socialButtonsDiv.getBoundingClientRect();
        let position = topRectPos + rect.height;
  
        this.removeClass(socialButtons);
        if (window.pageYOffset >= topRectPos && window.pageYOffset <= position) {
          socialButtons.classList.add("social-buttons--fixed");
        } else if (window.pageYOffset > position) {
          socialButtons.classList.add("social-buttons")
          socialButtons.classList.add("social-buttons--fixed-bottom");
        } else {
          socialButtons.classList.add("social-buttons");
        }
      }      
    }
  }

  removeClass(element:any){
    element.classList.forEach(className => {
      if(className != 'scroll-social'){
        element.classList.remove(className);
      }
    });
  }
}
