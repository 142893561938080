import { Component, Input, OnInit } from '@angular/core';
import { keyValueOfMaster } from '../../constants';

@Component({
  selector: 'pls-card-listbox',
  templateUrl: './pls-card-listbox.component.html',
  styleUrls: ['./pls-card-listbox.component.scss']
})
export class PlsCardListboxComponent implements OnInit {

  @Input() boxs: keyValueOfMaster[] = [];
  @Input() title = '';
  @Input() class = '';

  constructor() { }

  ngOnInit(): void {
  }

}
