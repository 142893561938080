import { Injectable } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

@Injectable({
    providedIn: 'root'
})
export class CommonService {
    private sessionStorage = window.sessionStorage;
    private readonly langKey = 'lang';
    private readonly instantPageKey = 'instantPageKey';
    private readonly backUrlKey = 'backUrlKey';

    constructor() { }

    setLanguage(lang: string) {
        this.sessionStorage.setItem(this.langKey, lang);
    }

    getLanguage(): string | null {
        return this.sessionStorage.getItem(this.langKey);
    }

    setPageName(pageName: string) {
        this.sessionStorage.setItem(this.instantPageKey, pageName);
    }

    getPageName(): string | null {
        return this.sessionStorage.getItem(this.instantPageKey);
    }

    setbackUrl(backUrl: string) {
        this.sessionStorage.setItem(this.backUrlKey, backUrl);
    }

    getbackUrl(): string | null {
        return this.sessionStorage.getItem(this.backUrlKey);
    }

    validateAllFields(formGroup: FormGroup, element: any) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.controls[field];
            if (control instanceof FormControl) {
                control.markAsTouched();
            }
            else if (control instanceof FormGroup) {
                this.validateAllFields(control, element);
            }
        });

        setTimeout(() => {
            this.scrollToFirstInvalidControl(element);
        });
    }

    scrollToFirstInvalidControl(element) {
        var subjects = element.querySelectorAll('.pf_has_errors');
        if (subjects.length > 0) {
            var subjectsArray = Array.from(subjects);
            let sorted = subjectsArray.sort(this.comparator);
            let firstInvalidElement = sorted[0] as Element;
            const firstInvalidControl: HTMLElement = firstInvalidElement.querySelector('.ng-touched .ng-invalid') || firstInvalidElement as HTMLElement;
            // const firstInvalidControl: HTMLElement = element.querySelector('.ng-touched .ng-invalid');
            if (firstInvalidControl != null) {
                firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
                firstInvalidControl.focus();
            }
        }
    }

    comparator(a, b) {
        if (parseInt(a.dataset.sort) < parseInt(b.dataset.sort))
            return -1;
        if (parseInt(a.dataset.sort) > parseInt(b.dataset.sort))
            return 1;
        return 0;
    }
}

export function isExistsArray(item) {
    return typeof (item) == 'object' ? true : false;
}
