<div *ngIf="iS_PDF_DISPLAYED"
    class="card_download card_download--hovered  ">
    <h3 class="card_download__title">
        <a [routerLink]="url">{{title}}</a>
    </h3>
    <div class="card_download__file" [innerHTML]="text">
    </div>
    <div class="card_download__download">
        <a [routerLink]="url" aria-label="">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">Download
                icon<g fill="none" fill-rule="evenodd" stroke="#0070AD" stroke-width="2">
                    <path class="border_svg" d="M1 18c0 9.39 7.61 17 17 17s17-7.61 17-17S27.39 1 18 1 1 8.61 1 18z">
                    </path>
                    <path d="M23.867 17.867l-3.958 3.958-2.042 2.042-6.02-6.02M11.846 27.846h12M17.846 8.303v14.543">
                    </path>
                </g></svg> </a>
    </div>
</div>