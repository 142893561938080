<div class="section container-full featured-jobs-dv">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-10">
                <h2 class="heading2">{{content.title}}</h2>
            </div>
        </div>
        <div class="row">
          <div class="section__block col-md-4 padding-10"
          *ngFor="let b of content.boxs">
            <div class="section__block section__height">
              <pls-box-container 
                [url]="b.url" 
                [subHeading]="b.subHeading"
                [boxHeight]="'110px'" 
                [isMore]="false" 
                [class]="'card_default--blue'">
              </pls-box-container>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="section__block col text-center">
            <pls-button-container [class]="'btn-our-green'" 
              [bodyText]="content.buttonContainer.bodyText"
              [isButton]="true"
              (clickBtn)="clickBtn($event)">
            </pls-button-container>
          </div>
        </div>
    </div>
</div>