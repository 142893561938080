import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IS_SOCIAL_DISPLAYED, SubHeaderMenu } from '../../constants';

@Component({
  selector: 'pls-sub-header',
  templateUrl: './pls-sub-header.component.html',
  styleUrls: ['./pls-sub-header.component.scss']
})
export class PlsSubHeaderComponent implements OnInit {

  iS_SOCIAL_DISPLAYED = IS_SOCIAL_DISPLAYED;
  navClass = '';
  @Input() defaultTitle = '';
  @Input() subHeading = '';
  @Input() defaultText = '';
  @Input() smallText = '';
  @Input() buttonText = '';
  @Input() btnUrl = '';
  @Input() shareText = '';
  @Input() imgSrc1 = '';
  @Input() imgSrc2 = '';
  @Input() subHeaderMenus: SubHeaderMenu[] = [];
  @Input() barTop = '495.962px';
  @Input() isFixedbar = false;
  @Input() isSocialButton = false;
  @Input() isTopSection = false;
  @Input() isSubHeading = false;
  @Input() isShareText = false;
  @Input() isSocialButtonTop = false;
  @Input() isSmalltext = false;
  @Input() isButton = false;
  @Input() backgroundSize = '';
  @Input() wrapperUrl = '';
  


  constructor(public translate: TranslateService) {
  }

  ngOnInit(): void {
  }
}
