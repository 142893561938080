<div>
  <app-header fxLayout="column"></app-header>
</div>
<div fxLayout="row" fxFlex="100">
  <div class="mid-container" fxLayout="column" fxFlex>
    <div class="">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<div>
  <app-footer fxLayout="column"></app-footer>
</div>
