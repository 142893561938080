<pls-sub-header 
    [isTopSection]="true" 
    [subHeading]="content.all.subHeading"
    [defaultText]="content.all.defaultText"
    [backgroundSize]="content.all.class"
    [imgSrc1]="content.all.imgSrc1"
    [imgSrc2]="content.all.imgSrc2">
</pls-sub-header>
<div class="section container">
    <div class="row">
        <div class="col-12">
            <div class="pagination pagination__top">
                <div class="pagination__current-page">{{pageNumber}}</div>
                <nav class="pagination__show">
                    <div class="pagination__show__label">{{content.all.show}}</div>
                    <ul>
                        <li role="presentation" [ngClass]="noOfRecordByPage==b.page?'active':''"
                            *ngFor="let b of listOfPage">
                            <a (click)="showPage(b.page)">{{b.page}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>
<div class="section container-full">
    <div class="container">
        <div class="section row">
            <div class="section__block col-md-4" [ngClass]="b.topClass"
                *ngFor="let b of boxs">
                <pls-box-container 
                    [url]="b.url" 
                    [title]="b.title" 
                    [subHeading]="b.subHeading" 
                    [bodyText]="b.bodyText"
                    [author]="b.author" 
                    [imgSrc2]="b.imgSrc2">
                </pls-box-container>
            </div>
        </div>
    </div>
</div>
<div class="section container">
    <div class="row">
        <div class="col-12">
            <div class="pagination pagination__bottom">
                <div class="pagination__current-page">{{pageNumber}}</div>
                <div class="pagination__buttons text-center">
                    <ng-container *ngFor="let b of content.all.buttonContainer">
                        <pls-button-container [class]="'btn-our-blue'"
                            [bodyText]="b.bodyText" [isButton]="true"
                            [returnValue]="b.bodyText == 'Previous'? currentIndex-1:currentIndex+1" (clickBtn)="clickBtn($event)"
                            *ngIf="!(b.bodyText == 'Next' && (this.currentIndex + 1) == totalpages)">
                        </pls-button-container>
                    </ng-container>
                </div>
                <nav class="pagination__show">
                    <div class="pagination__show__label">{{content.all.show}}</div>
                    <ul>
                        <li role="presentation" [ngClass]="noOfRecordByPage==b.page?'active':''"
                            *ngFor="let b of listOfPage">
                            <a (click)="showPage(b.page)">{{b.page}}</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</div>