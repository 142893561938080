<a *ngIf="!isButton" 
    [ngClass]="class" 
    type="button" 
    [href]="url">
    {{ bodyText }}
</a>
<button 
    *ngIf="isButton" 
    [ngClass]="class" 
    type="button"
    [disabled]="disabled"
    (click)="onClick()">
    {{ bodyText }}
</button>