import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pl-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  content: any;
  constructor(public translate: TranslateService) { }

  ngOnInit(): void {
    this.content = this.translate.instant('errorPage');
  }

}
