import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pls-box-container',
  templateUrl: './pls-box-container.component.html',
  styleUrls: ['./pls-box-container.component.scss']
})
export class PlsBoxContainerComponent implements OnInit {

  @Input() url: string = '#';
  @Input() title = '';
  @Input() subHeading = '';
  @Input() bodyText = '';
  @Input() author = '';
  @Input() authorUrl = '';
  @Input() date = '';
  @Input() imgSrc1 = '';
  @Input() imgSrc2 = '';
  @Input() boxHeight = '100%';
  @Input() boxwidth = '100%';
  @Input() isVisible = false;
  @Input() isMore: boolean | string = true;
  @Input() class = 'card_default--grey';
  @Input() mediaClass = 'card_default--shape';
  @Input() logoInvent = false;
  @Input() defaultLogo = { src: '', alt: '' };
  isDefaultLogo = false;
  isMoreIcon = true;


  constructor(private route: Router) {
    this.defaultLogo = { src: '', alt: '' };
  }

  ngOnInit(): void {
    if (this.url == '') {
      this.url = this.route.url;
    }
    if (this.defaultLogo != null && this.defaultLogo.src != '') {
      this.isDefaultLogo = true;
    }
    if (!this.isMore || this.isMore == 'false') {
      this.isMoreIcon = false;
    }
  }
}
