<div *ngIf="iS_SOCIAL_DISPLAYED" 
    data-stick-to=".main-content" class="scroll-social">
    <a href="https://www.facebook.com/PointlabIndia/" target="_blank"
        class="social-buttons__button social-buttons__button--fb">
        <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <title>Facebook icon</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Facebook">
                    <rect id="Rectangle-7" fill="#3D5E96" x="0" y="0" width="36" height="36"
                        rx="2.13333333"></rect>
                    <path
                        d="M17.4871795,24.5333333 L17.4871795,16.7487179 L20.0923077,16.7487179 L20.4923077,13.7128205 L17.4871795,13.7128205 L17.4871795,11.774359 C17.4871795,10.9025641 17.7230769,10.2974359 18.9846154,10.2974359 L20.5948718,10.2974359 L20.5948718,7.58974359 C20.3179487,7.54871795 19.3641026,7.46666667 18.2564103,7.46666667 C15.9384615,7.46666667 14.3487179,8.88205128 14.3487179,11.4769231 L14.3487179,13.7128205 L11.7333333,13.7128205 L11.7333333,16.7487179 L14.3487179,16.7487179 L14.3487179,24.5333333 L17.4871795,24.5333333 Z"
                        id="" fill="#FFFFFF" fill-rule="nonzero"></path>
                </g>
            </g>
        </svg>
    </a>
    <a href="https://twitter.com/PointlabIndia" target="_blank"
        class="social-buttons__button social-buttons__button--twitter">
        <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <title>Twitter icon</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Twitter">
                    <rect id="Rectangle-7" fill="#46ADE9" x="0" y="0" width="36" height="36"
                        rx="2.13333333"></rect>
                    <path
                        d="M7.46666667,21.2574713 C9.11111933,22.3379364 10.8999903,22.8781609 12.8333333,22.8781609 C14.077784,22.8781609 15.244439,22.67414 16.3333333,22.266092 C17.4222277,21.8580439 18.3555517,21.3120724 19.1333333,20.6281609 C19.911115,19.9442495 20.5805528,19.156901 21.1416667,18.266092 C21.7027806,17.3752829 22.1194431,16.4442577 22.3916667,15.4729885 C22.6638903,14.5017193 22.8,13.5333382 22.8,12.5678161 C22.8,12.3609185 22.7944445,12.2057476 22.7833333,12.1022989 C23.4833368,11.5850549 24.0666643,10.9586244 24.5333333,10.2229885 C23.911108,10.5103463 23.2388925,10.7057466 22.5166667,10.8091954 C23.272226,10.349423 23.7888875,9.67701592 24.0666667,8.79195402 C23.3444408,9.22873782 22.6000038,9.52183833 21.8333333,9.67126437 C21.1555522,8.91263989 20.3055607,8.53333333 19.2833333,8.53333333 C18.3166618,8.53333333 17.4916701,8.88677807 16.8083333,9.59367816 C16.1249966,10.3005782 15.7833333,11.154018 15.7833333,12.154023 C15.7833333,12.487358 15.8111108,12.7632173 15.8666667,12.9816092 C14.4333262,12.901149 13.0888952,12.5275895 11.8333333,11.8609195 C10.5777715,11.1942495 9.5111155,10.3034539 8.63333333,9.18850575 C8.3111095,9.76322126 8.15,10.3724106 8.15,11.016092 C8.15,12.3264433 8.6555505,13.3321804 9.66666667,14.0333333 C9.14444183,14.021839 8.58889183,13.8724152 8,13.5850575 L8,13.6195402 C8,14.4816135 8.277775,15.2488472 8.83333333,15.9212644 C9.38889167,16.5936815 10.0722182,17.010344 10.8833333,17.1712644 C10.5611095,17.2632189 10.277779,17.3091954 10.0333333,17.3091954 C9.88888817,17.3091954 9.67222367,17.2862071 9.38333333,17.2402299 C9.61666783,17.9643714 10.0305526,18.5620666 10.625,19.0333333 C11.2194474,19.5046001 11.8944407,19.745977 12.65,19.7574713 C11.3611047,20.7919592 9.91111917,21.3091954 8.3,21.3091954 C8.01110967,21.3091954 7.73333467,21.2919542 7.46666667,21.2574713 L7.46666667,21.2574713 Z"
                        id="Path" fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>
    </a>
    <a href="https://www.linkedin.com/company/Pointlab/" target="_blank"
        class="social-buttons__button social-buttons__button--in">
        <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <title>LinkedIn icon</title>
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="LinkedIn">
                    <rect id="Rectangle-7" fill="#0070AD" x="0" y="0" width="36" height="36"
                        rx="2.13333333"></rect>
                    <path
                        d="M20.4822123,12.4050699 L20.6933538,12.4096489 C22.2071139,12.4754634 24.4272139,13.2861468 24.5296556,17.1020282 L24.5333333,17.3797365 L24.5333333,23.4871474 L20.8249255,23.4871474 L20.8249255,17.6196204 C20.8249255,16.417032 20.4822123,15.2122208 19.0884135,15.2122208 C17.7526897,15.2122208 17.1616898,16.3187228 17.1147588,17.4952956 L17.1117063,17.6490738 L17.1117063,23.4871474 L13.3927506,23.4871474 L13.3927506,12.7436915 L17.1117063,12.7436915 L17.1117063,14.1900578 C18.0902522,12.926525 18.9405583,12.4050699 20.4822123,12.4050699 Z M11.0568978,12.4050699 L11.0568978,23.1913055 L7.46666667,23.1913055 L7.46666667,12.4050699 L11.0568978,12.4050699 Z M9.5026852,7.46666667 C10.7305132,7.46666667 11.4920773,8.27485723 11.5076195,9.33172181 C11.5076195,10.3108758 10.7995536,11.1200939 9.64025666,11.1916361 L9.45605882,11.196777 C8.24377298,11.196777 7.46666667,10.3575021 7.46666667,9.33172181 C7.46666667,8.27485723 8.27485723,7.46666667 9.5026852,7.46666667 Z"
                        id="Shape" fill="#FFFFFF"></path>
                </g>
            </g>
        </svg>
    </a>
</div>
