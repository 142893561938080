import { enableProdMode, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from './token-storage.service';
import { environment } from 'src/environments/environment';
import { MailDetails } from '../../components/pls-contact-us/mail-details';

enableProdMode();
const AUTH_API = environment.BaseUrl;


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  errorMessage = '';
  tokenStorage!: TokenStorageService;

  constructor(private http: HttpClient) { }

  sendEmail(postDatas: MailDetails): Observable<any> {
    
    return this.http.post(AUTH_API + 'api/SendEmail?postDatas='+JSON.stringify(postDatas), this.get_HttpOption());
  }

  get_HttpOption(): any {
    headers: new HttpHeaders(
      {
        //'Authorization': 'bearer ' + this.tokenStorage.getToken(),
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'Cross-Origin-Resource-Policy':'same-site, same-origin',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type,Cross-Origin-Resource-Policy,Access-Control-Allow-Credentials,Access-Control-Allow-Origin,Access-Control-Allow-Methods, Access-Control-Allow-Headers, X-Requested-With'
      })
  }
}


