import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PlsBoxContainerComponent } from './components/pls-box-container/pls-box-container.component';
import { PlsButtonContainerComponent } from './components/pls-button-container/pls-button-container.component';
import { PlsSubHeaderComponent } from './components/pls-sub-header/pls-sub-header.component';
import { PlsSliderContainerComponent } from './components/pls-slider-container/pls-slider-container.component';
import { PlsListCardContainerComponent } from './components/pls-listcard-container/pls-listcard-container.component';
import { ScrollDirective } from './directive/scroll.directive';
import { PlsTabContainerComponent } from './components/pls-tab-container/pls-tab-container.component';
import { PlsCardDownloadContainerComponent } from './components/pls-card-download-container/pls-card-download-container.component';
import { RedirectGuard } from './services/RedirectGuard';
import { SafeHtmlPipe } from './pipe/safeHtml.pipe';
import { PlsCardGraphboxComponent } from './components/pls-card-graphbox/pls-card-graphbox.component';
import { PlsCardListboxComponent } from './components/pls-card-listbox/pls-card-listbox.component';
import { PlsScrollSocialComponent } from './components/pls-scroll-social/pls-scroll-social.component';
import { PlsCardTweetComponent } from './components/pls-card-tweet/pls-card-tweet.component';
import { PlsExpertSocialComponent } from './components/pls-expert-social/pls-expert-social.component';
import { AllStoriesComponent } from './components/all-stories/all-stories.component';
import { PlsFeaturedJobsComponent } from './components/pls-featured-jobs/pls-featured-jobs.component';
import { PlsContactUsComponent } from './components/pls-contact-us/pls-contact-us.component';
import en from '../../assets/i18n/en.json';
import fr from '../../assets/i18n/fr.json';
import { FormsModule } from '@angular/forms';
import { RecaptchaFormsModule,  RECAPTCHA_SETTINGS,  RecaptchaSettings, RecaptchaModule } from "ng-recaptcha";
import { environment } from 'src/environments/environment';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,    
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forChild()
  ],
  exports: [
    PlsBoxContainerComponent,
    PlsButtonContainerComponent,
    PlsSubHeaderComponent,
    PlsSliderContainerComponent,
    PlsListCardContainerComponent,
    PlsTabContainerComponent,
    ScrollDirective,
    SafeHtmlPipe,
    PlsCardDownloadContainerComponent,
    PlsCardGraphboxComponent,
    PlsCardListboxComponent,
    PlsScrollSocialComponent,
    PlsCardTweetComponent,
    PlsExpertSocialComponent,
    PlsFeaturedJobsComponent,
    PlsContactUsComponent
  ],
  declarations: [
    PlsBoxContainerComponent,
    PlsButtonContainerComponent,
    PlsSubHeaderComponent,
    PlsSliderContainerComponent,
    PlsListCardContainerComponent,
    PlsTabContainerComponent,
    ScrollDirective,
    SafeHtmlPipe,
    PlsCardDownloadContainerComponent,
    PlsCardGraphboxComponent,
    PlsCardGraphboxComponent,
    PlsCardListboxComponent,
    PlsScrollSocialComponent,
    PlsCardTweetComponent,
    PlsExpertSocialComponent,
    AllStoriesComponent,
    PlsFeaturedJobsComponent,
    PlsContactUsComponent
  ],
  entryComponents: [ 
    PlsBoxContainerComponent,
    PlsButtonContainerComponent,
    PlsSubHeaderComponent,
    PlsSliderContainerComponent,
    PlsListCardContainerComponent,
    PlsTabContainerComponent,
    PlsCardDownloadContainerComponent,
    PlsCardGraphboxComponent,
    PlsCardListboxComponent,
    PlsScrollSocialComponent,
    PlsCardTweetComponent,
    PlsExpertSocialComponent,
    PlsFeaturedJobsComponent,
    PlsContactUsComponent
  ],
  providers:[
    RedirectGuard,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.RECAPTCHA_V2_KEY
      } as RecaptchaSettings
    }
  ]
})
export class SharedModule { 
  constructor(private translateService: TranslateService) {
    this.translateService.setTranslation('en', en, true);
    this.translateService.setTranslation('fr', fr, true);
  }
}
