import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pls-expert-social',
  templateUrl: './pls-expert-social.component.html',
  styleUrls: ['./pls-expert-social.component.scss']
})
export class PlsExpertSocialComponent implements OnInit {

  @Input() writeToMe = '';
  @Input() writeToMeUrl = '';  
  @Input() twitter = '';  
  @Input() twitterUrl = '';
  @Input() linkedIn = '';
  @Input() linkedInUrl = '';   

  constructor() { }

  ngOnInit(): void {
  }

}
