<section class="section section--404">
    <div class="container-full">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-6 col-xl-4">
                    <h1 class="section__regular-title">{{content.title}}</h1>
                    <p>{{content.para1}}</p>
                    <p class="section__bigger">{{content.para2}}</p>
                    <div class="menu-404-container">
                        <ul id="menu-404" class="section__bigger">
                            <li id="menu-item-55204"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home menu-item-55204">
                                <a href="/">{{content.link}}</a></li>                            
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="error__bg">
            <img src="assets/images/404.png"
                alt="" role="presentation" aria-hidden="true">
        </div>
    </div>
</section>