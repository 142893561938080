export class MailDetails {
  constructor(
    public destination: string = '',
    public body: string = '',
    public subject: string = '',
    public Id: number = 1,
    public Name: string = '',
    public JobTitle: string = '',
    public Email: string = '',
    public Research: string = '',
    public Phone: string = '',
    public Company: string = '',
    public Industry: string = '',
    public Position: string = '',
    public Country: string = '',
    public Relationship: string = '',
    public RFI_RFP: string = '',
    public Newsletter: string = '',
    public Message: string = '',
    public AreaOfInterest: string = ''
  ) {
    //this.firstName = firstName;
  }
}