import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'pls-featured-jobs',
  templateUrl: './pls-featured-jobs.component.html',
  styleUrls: ['./pls-featured-jobs.component.scss']
})
export class PlsFeaturedJobsComponent implements OnInit {

  content: any;

  constructor(
    public translate: TranslateService,
    public router: Router,    
    public commonService: CommonService
    ) { }

  ngOnInit(): void {
    this.content = this.translate.instant('jobs.featuredJobs');
  }

  clickBtn(index) {
    this.commonService.setPageName('jobs');
    this.commonService.setbackUrl(this.router.url);
    this.router.navigateByUrl(this.content.buttonContainer.url);
  }
}
