import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { IS_PDF_DISPLAYED } from '../../constants';

@Component({
  selector: 'pls-card-download-container',
  templateUrl: './pls-card-download-container.component.html',
  styleUrls: ['./pls-card-download-container.component.scss']
})
export class PlsCardDownloadContainerComponent implements OnInit {

  @Input() url = '';
  @Input() title = '';
  @Input() text = '';
  iS_PDF_DISPLAYED= IS_PDF_DISPLAYED;

  constructor(private route: Router) {
  }

  ngOnInit(): void {
    if (this.url == '') {
      this.url = this.route.url;
    }
  }
}
