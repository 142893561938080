import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pls-button-container',
  templateUrl: './pls-button-container.component.html',
  styleUrls: ['./pls-button-container.component.scss']
})
export class PlsButtonContainerComponent implements OnInit {

  @Input() bodyText = '';
  @Input() class = 'btn-our-blue';
  @Input() url = '';
  @Input() returnValue: any = true;
  @Input() isButton = false;
  @Input() disabled = false;
  @Output() clickBtn = new EventEmitter<any>();

  constructor(private route: Router) {
  }

  ngOnInit(): void {
    if (this.url == '') {
      this.url = `#${this.route.url}`;
    }
  }

  onClick() {
    this.clickBtn.emit(this.returnValue);
  }
}
