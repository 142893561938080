import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pls-card-tweet',
  templateUrl: './pls-card-tweet.component.html',
  styleUrls: ['./pls-card-tweet.component.scss']
})
export class PlsCardTweetComponent implements OnInit {

  @Input() name = '';
  @Input() text = '';
  @Input() url = '';
  @Input() twitterUrl = '';

  constructor(private route: Router) {
  }

  ngOnInit(): void {
    if (this.url == '') {
      this.url = this.route.url;
    }
  }

}
