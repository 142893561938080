import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { HashLocationStrategy, Location, LocationStrategy } from '@angular/common';
import en from '../assets/i18n/en.json';
import fr from '../assets/i18n/fr.json';
import { HttpClient, HttpClientModule } from '@angular/common/http';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    HttpClientModule,
    FormsModule,
    LayoutModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    this.translateService.setTranslation('en', en, true);
    this.translateService.setTranslation('fr', fr, true);
  }
}

// const __stripTrailingSlash = (Location as any).stripTrailingSlash;
// Location.stripTrailingSlash = function (url) {
//   if (url.endsWith('/')) {
//     url = url;
//   }
//   else {
//     url = url + '/';
//   }
//   const queryString$ = url.match(/([^?]*)?(.*)/);
//   if (queryString$ != null && queryString$[2].length > 0) {
//     return /[^\/]\/$/.test(queryString$[1]) ? queryString$[1] + '.' + queryString$[2] : __stripTrailingSlash(url);
//   }
//   return /[^\/]\/$/.test(url) ? url : __stripTrailingSlash(url);
// };

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
