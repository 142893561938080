import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './modules/error-page/error-page.component';
import { AllStoriesComponent } from './shared/components/all-stories/all-stories.component';
import { RedirectGuard } from './shared/services/RedirectGuard';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled'
};

const routes: Routes = [
  {
      path:'industries',
      loadChildren :() => import('./modules/industries/industries.module')
       .then( module => module.IndustriesModule),
       data:{title : 'Industries'}
  },
  {
    path: 'jobs',
    loadChildren :() => import('./modules/jobs/jobs.module')
     .then( module => module.JobsModule),
     data:{title : 'Jobs'}
  },
  {
    path: 'resources',
    loadChildren :() => import('./modules/resources/resources.module')
     .then( module => module.ResourcesModule),
     data:{title : 'Resources'}
  },
  {
    path: 'client-story',
    loadChildren :() => import('./modules/client-story/client-story.module')
     .then( module => module.ClientStoryModule),
     data:{title : 'Client-Story'}
  },
  {
    path: 'employee-stories',
    loadChildren :() => import('./modules/employee-stories/employee-stories.module')
     .then( module => module.EmployeeStoriesModule),
     data:{title : 'Employee-Stories'}
  },
  {
    path: 'testimonials',
    loadChildren :() => import('./modules/testimonials/testimonials.module')
     .then( module => module.TestimonialsModule),
     data:{title : 'Testimonials'}
  },
  {
    path: 'news',
    loadChildren :() => import('./modules/news/news.module')
     .then( module => module.NewsModule),
     data:{title : 'News'}
  },
  {
    path: '2017',
    loadChildren :() => import('./modules/2017/2017.module')
     .then( module => module.Two2017Module),
     data:{title : '2017'}
  },
  {
    path: '2018',
    loadChildren :() => import('./modules/2018/2018.module')
     .then( module => module.II2018Module),
     data:{title : '2018'}
  },
  {
    path: '2020',
    loadChildren :() => import('./modules/2020/2020.module')
     .then( module => module.II2020Module),
     data:{title : '2020'}
  },
  {
    path: '2021',
    loadChildren :() => import('./modules/2021/2021.module')
     .then( module => module.II2021Module),
     data:{title : '2021'}
  },
  {
    path: '2022',
    loadChildren :() => import('./modules/2022/2022.module')
     .then( module => module.II2022Module),
     data:{title : '2022'}
  },
  {
    path: 'business-services-thought-leadership',
    loadChildren :() => import('./modules/business-services-thought-leadership/business-services-thought-leadership.module')
     .then( module => module.BusinessServicesThoughtLeadershipModule),
     data:{title : 'business-services-thought-leadership'}
  },
  {
    path: 'business-services-thought-leadership/:ID',
    loadChildren :() => import('./modules/business-services-thought-leadership/business-services-thought-leadership.module')
     .then( module => module.BusinessServicesThoughtLeadershipModule),
     data:{title : 'business-services-thought-leadership'}
  },
  {
    path: 'research-reports',
    loadChildren :() => import('./modules/research-reports/research-reports.module')
     .then( module => module.ResearchReportsModule),
     data:{title : 'Research Reports'}
  },
  {
    path: 'dti-report-subscription-2018-preview',
    loadChildren :() => import('./modules/dti-report-subscription-2018-preview/dti-report-subscription-2018-preview.module')
     .then( module => module.DtiReportSubscription2018PreviewModule),
     data:{title : 'Pointlab Research Institute Subscription'}
  },
  {
    path: 'experts',
    loadChildren :() => import('./modules/experts/expert.module')
     .then( module => module.ExpertModule),
     data:{title : 'Experts'}
  },
  {
    path: 'terms-of-use',
    loadChildren :() => import('./modules/terms-of-use/terms-of-use.module')
     .then( module => module.TermsOfUseModule),
     data:{title : 'Terms of Use'}
  },
  {
    path: 'privacy-policy',
    loadChildren :() => import('./modules/privacy-policy/privacy-policy.module')
     .then( module => module.PrivacyPolicyModule),
     data:{title : 'Privacy Policy'}
  },
  {
    path: 'research',
    loadChildren :() => import('./modules/research/research.module')
     .then( module => module.ResearchModule),
     data:{title : 'Research'}
  },
  {
    path:'services',
    loadChildren: () => import('./modules/services/services.module')
    .then(module => module.ServicesModule),
    data:{title : 'Services'}
  },
  {
    path:'global-technology-partners',
    loadChildren: () => import('./modules/partners/partners.module')
      .then( module => module.PartnersModule),
      data:{title : 'About us'}
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module')
      .then(module => module.SearchModule),
      data:{title : 'Search - Point Lab'}
  },
  {
    path:'about-us',
    loadChildren: () => import('./modules/about-us/about-us.module')
      .then( module => module.AboutUsModule),
      data:{title : 'About us'}
  },
  {
    path:'careers',
    loadChildren: () => import('./modules/careers/careers.module')
      .then( module => module.CareersModule),
      data:{title : 'Careers'}
  },
  {
    path: 'worldretailbankingreport',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'http://www.worldretailbankingreport.com/'
    }
  },
  {
    path: 'pointlab',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'http://www.pointlab.ca/'
    },
  },
  {
    path: 'efma',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://www.efma.com/'
    }
  },
  {
    path: 'all-client-stories',
    component: AllStoriesComponent
  },
  {
    path:'',
      loadChildren :() => import('./modules/home/home.module')
       .then( module => module.HomeModule),
       data:{title : 'Point Lab - Get The Future You Want'}
  },
  {
    path: '**',
    component: ErrorPageComponent,
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

