<div class="container">
  <div class="row">
    <div class="w3-row">
      <a href="javascript:void(0)" *ngFor="let t of tabs; let i = index" (click)="tabClick(i);">
        <div class="w3-third tablink w3-padding" [ngClass]="tabIndex===i? 'w3-border-red' : 'w3-bottombar'">
          {{t.title}}</div>
      </a>
      <a href="javascript:void(0)">
        <div class="w3-third tablink w3-padding"></div>
      </a>
    </div>
  </div>
</div>
<div class="section section--grey">
  <div class="container">
    <div class="row">
      <div class="w3-row">
        <div class="w3-container row" *ngFor="let t of tabs; let i = index"
          [ngClass]="tabIndex===i? 'activeTab' : 'inActiveTab'">
          <ng-container>
            <div *ngIf="t.data; then first else secound"></div>
            <ng-template #first>
              <div class="section__block col-12 col-md-4" *ngFor="let d of t.data;">
                <div class="card_tab">
                  <div class="card_tab__inner">
                    <pls-box-container [subHeading]="d.subHeading" [isMore]="false" [boxHeight]="'360px'" [class]="''">
                    </pls-box-container>
                  </div>
                  <div class="card_tab__hover">
                    <pls-box-container [url]="d.url" [subHeading]="d.subHeading" [bodyText]="d.bodyText"
                      [class]="'card_default--blue'" [boxHeight]="'360px'">
                    </pls-box-container>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template #secound>
              <div class="section__block col-12 col-md-12">
                <div class="card_tab">
                  <div class="card_tab__inner">
                    <div class="section__block slider__item slider__item--active col-sm-12 col-md-12"
                      *ngFor="let b of t.boxs">
                      <pls-card-listbox [title]="b.title" [class]="b.class" [boxs]="b.ul"></pls-card-listbox>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>