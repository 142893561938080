<div [ngClass]="class" class="card_default" [ngStyle]="{'height':boxHeight}">
    <!-- <a [routerLink]="url"> -->
        <div class="card_default__content">
            <div class="card_default__logo"  *ngIf="isDefaultLogo">
				<img [src]="defaultLogo.src" [alt]="defaultLogo.alt">
			</div>
            <div class="card_default__tags" *ngIf="title">
                <span>{{ title }}</span>
            </div>
            <h3 class="card_default__title" *ngIf="subHeading">
                <a [routerLink]="url">{{ subHeading }}</a>
            </h3>
            <div class="card_default__author" *ngIf="author">
                <a [routerLink]="authorUrl">{{author}}</a>
            </div>
            <div class="card_default__date" *ngIf="date">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21">Date icon
                    <path fill="#000" d="M16 7H2v10h14V7zm2 0v12H0V3h2.5v.5a2 2 0 1 0 4 0V3h5v.5a2 2 0 1 0 4 0V3H18v4zm-4.5-6A1.5 1.5 0 0 1 15 2.5v1a1.5 1.5 0 0 1-3 0v-1A1.5 1.5 0 0 1 13.5 1zm-9 0A1.5 1.5 0 0 1 6 2.5v1a1.5 1.5 0 0 1-3 0v-1A1.5 1.5 0 0 1 4.5 1zM4 9h2v2H4V9zm0 4h2v2H4v-2zm4-4h2v2H8V9zm0 4h2v2H8v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z">
                    </path>
                </svg>
                {{date}}
            </div>
            <div class="card_default__text" *ngIf="bodyText">
                <p>{{ bodyText }}</p>
            </div>
            <div class="card_default__links">
                <div class="card_default__more" *ngIf="isMoreIcon">
                    <a [routerLink]="url" aria-label="">
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">Read more
                            icon
                            <g fill="none" fill-rule="evenodd" stroke-width="2">
                                <path class="border_svg"
                                    d="M1 18c0 9.39 7.61 17 17 17s17-7.61 17-17S27.39 1 18 1 1 8.61 1 18z"></path>
                                <path d="M15.018 11.997l3.958 3.958 2.042 2.042-6.021 6.02"></path>
                            </g>
                        </svg>
                    </a>
                </div>
                <div class="logo-invent" *ngIf="logoInvent"></div>
            </div>
        </div>
        <div class="card_default__media" [ngClass]="mediaClass" *ngIf="imgSrc1 || imgSrc2" 
        [ngStyle]="{'background-image': 'url('+imgSrc1+')'}">
            <!-- card_default__media -->
            <a [routerLink]="url">
                <img *ngIf="imgSrc2" [src]="imgSrc2" [ngStyle]="{'width':boxwidth}" class="card_default__media--inner">
                <!-- <div class="card_default__media--inner"
                    [ngStyle]="{'width':boxwidth,'background-image': 'url('+imgSrc2+')'}"></div> -->
            </a>
        </div>
    <!-- </a> -->
</div>
