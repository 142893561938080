import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TabSection } from '../../constants';

@Component({
  selector: 'pls-tab-container',
  templateUrl: './pls-tab-container.component.html',
  styleUrls: ['./pls-tab-container.component.scss']
})
export class PlsTabContainerComponent implements OnInit {

  @Input() tabs: TabSection[] = [];
  tabIndex = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  tabClick(index: number) {
    this.tabIndex = index;
  }
}
