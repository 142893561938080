<div class="pf_forms__container ">
    <div class="alert alert-success" role="alert" *ngIf="successMsg">
        {{ successMsg }}
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="errorMsg">
        {{ errorMsg }}
    </div>
    <form id="pf_form_61716" class="pf_form__form" #f="ngForm">
        <div *ngIf="isName" [attr.data-sort]="nameOrder" class="pf_field pf_field--text pf-required"
            [ngClass]="Name.invalid && (Name.dirty || Name.touched)? 'pf_has_errors block-'+nameOrder:'block-'+nameOrder" data-validate="text">
            <label for="_pf_61716_1626">{{nameLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span></label>
            <input type="text" class="pf_field__input empty" id="Name" name="Name" ngModel required #Name="ngModel">
            <span *ngIf="Name.invalid && (Name.dirty || Name.touched)" class="pf_error">
                {{content.text.error}}</span>
        </div>
        <div *ngIf="isFName" [attr.data-sort]="fNameOrder" class="pf_field pf_field--text pf-required"
            [ngClass]="fName.invalid && (fName.dirty || fName.touched)? 'pf_has_errors block-'+fNameOrder:'block-'+fNameOrder" data-validate="text">
            <label for="_pf_61716_5376">{{fNameLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span></label>
            <input type="text" class="pf_field__input empty" id="fName" name="fName" ngModel required #fName="ngModel" 
                tooltips tooltip-template="test" tooltip-show-trigger="focusin" tooltip-hide-trigger="focusout">
            <span *ngIf="fName.invalid && (fName.dirty || fName.touched)" class="pf_error">
                {{content.text1.error}}</span>
        </div>
        <div *ngIf="isLName" [attr.data-sort]="lNameOrder" class="pf_field pf_field--text pf-required"
            [ngClass]="lName.invalid && (lName.dirty || lName.touched)? 'pf_has_errors block-'+lNameOrder:'block-'+lNameOrder" data-validate="text">
            <label for="_pf_61716_1626">{{lNameLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span></label>
            <input type="text" class="pf_field__input empty" id="lName" name="lName" ngModel required #lName="ngModel">
            <span *ngIf="lName.invalid && (lName.dirty || lName.touched)" class="pf_error">
                {{content.text2.error}}</span>
        </div>
        <div *ngIf="isJobTitle" [attr.data-sort]="jobTitleOrder" class="pf_field pf_field--text pf-required"
            [ngClass]="jobTitle.invalid && (jobTitle.dirty || jobTitle.touched)? 'pf_has_errors block-'+jobTitleOrder:'block-'+jobTitleOrder"
            data-validate="text">
            <label for="_pf_61716_5318">{{jobTitleLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span></label>
            <input type="text" class="pf_field__input empty" id="jobTitle" name="jobTitle" ngModel required
                #jobTitle="ngModel">
            <span *ngIf="jobTitle.invalid && (jobTitle.dirty || jobTitle.touched)" class="pf_error">
                {{content.text3.error}}</span>
        </div>
        <div *ngIf="isEmail" [attr.data-sort]="emailOrder" class="pf_field pf_field--email pf-required"
            [ngClass]="email.invalid && (email.dirty || email.touched)? 'pf_has_errors block-'+emailOrder:'block-'+emailOrder" data-validate="email">
            <label for="_pf_61716_6373">{{emailLabel}}
                &nbsp;<span class="pf-required--symbol"> * </span>
            </label>
            <input type="email" class="pf_field__input empty" id="email" name="email" ngModel required #email="ngModel">
            <span *ngIf="email.invalid && (email.dirty || email.touched)" class="pf_error">
                {{content.text4.error}}</span>
        </div>
        <div *ngIf="isResearch" [attr.data-sort]="researchOrder" class="pf_field pf_field--text pf-required"
            [ngClass]="research.invalid && (research.dirty || research.touched)? 'pf_has_errors block-'+researchOrder:'block-'+researchOrder"
            data-validate="text">
            <label for="_pf_61716_5318">{{researchLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span></label>
            <input type="text" class="pf_field__input empty" id="research" name="research" ngModel
                required #research="ngModel">
            <span *ngIf="research.invalid && (research.dirty || research.touched)" class="pf_error">
                {{content.text11.error}}</span>
        </div>
        <div *ngIf="isPhone" [ngClass]="'block-'+ phoneOrder" [attr.data-sort]="phoneOrder" class="pf_field pf_field--tel "
            [ngClass]="tel.invalid && (tel.dirty || tel.touched)? 'pf_has_errors block-'+phoneOrder:'block-'+phoneOrder" data-validate="tel">
            <label for="_pf_61716_1122">{{phoneLabel}} </label>
            <input type="tel" class="pf_field__input empty" id="tel" name="tel" ngModel required #tel="ngModel"
                pattern="^[0-9-+s()]*$">
        </div>
        <div *ngIf="isCompany" [attr.data-sort]="companyOrder" class="pf_field pf_field--text pf-required"
            [ngClass]="company.invalid && (company.dirty || company.touched)? 'pf_has_errors block-'+companyOrder:'block-'+companyOrder" data-validate="text">
            <label for="_pf_61716_1701">{{companyLabel}}
                &nbsp;<span class="pf-required--symbol">
                    * </span></label>
            <input type="text" class="pf_field__input empty" id="company" name="company" ngModel required
                #company="ngModel">
            <span *ngIf="company.invalid && (company.dirty || company.touched)" class="pf_error">
                {{content.text6.error}}</span>
        </div>
        <div *ngIf="isIndustry" [attr.data-sort]="industryOrder" class="pf_field pf_field--select pf-required"
            [ngClass]="industry.invalid && (industry.dirty || industry.touched)? 'pf_has_errors block-'+industryOrder:'block-'+industryOrder"
            data-validate="select">
            <label for="_pf_61716_1886">{{industryLabel}}
                &nbsp;<span class="pf-required--symbol"> * </span>
            </label>

            <select class="pf_field__input empty" id="industry" name="industry" ngModel required #industry="ngModel"
                tabindex="-1" aria-hidden="false">
                <option value=""> </option>
                <option *ngFor="let c of industries" [ngValue]="c.value">{{c.name}}</option>
            </select>
            <span *ngIf="industry.invalid && (industry.dirty || industry.touched)" class="pf_error">
                {{content.text7.error}}</span>
        </div>
        <div *ngIf="isPosition" [attr.data-sort]="positionOrder" class="pf_field pf_field--select pf-required"
            [ngClass]="position.invalid && (position.dirty || position.touched)? 'pf_has_errors block-'+positionOrder:'block-'+positionOrder"
            data-validate="select">
            <label for="_pf_61716_9095">{{positionLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span> </label>

            <select class="pf_field__input empty" id="position" name="position" ngModel required #position="ngModel"
                tabindex="-1" aria-hidden="false">
                <option value=""> </option>
                <option *ngFor="let c of position_Level" [ngValue]="c.value">{{c.name}}</option>
            </select>
            <span *ngIf="position.invalid && (position.dirty || position.touched)" class="pf_error">
                {{content.text8.error}}</span>
        </div>
        <div *ngIf="isCountry" [attr.data-sort]="countryOrder" class="pf_field pf_field--select pf-required"
            [ngClass]="country.invalid && (country.dirty || country.touched)? 'pf_has_errors block-'+countryOrder:'block-'+countryOrder"
            data-validate="select">
            <label for="_pf_61716_4473">{{countryLabel}}
                &nbsp;<span class="pf-required--symbol"> * </span>
            </label>
            <select class="pf_field__input empty select2-hidden-accessible" id="country" name="country" [(ngModel)]="selectedCountry" required #country="ngModel"
                tabindex="-1" aria-hidden="false">
                <option value="" selected="false"> </option>
                <option *ngFor="let c of countries" [ngValue]="c.value">{{c.name}}</option>
            </select>
            <span class="select2 select2-container select2-container--default select2-container--below" (click)="countryDropdown()"
                [ngClass]="isCountryDisplay?'select2-container--open':'select2-container--focus'"
                dir="ltr" aria-hidden="true">
                <span class="selection">
                    <span class="select2-selection select2-selection--single" aria-haspopup="true" aria-expanded="false" tabindex="0"
                        aria-labelledby="select2-_pf_459940_4811-container" role="combobox"
                        >
                        <span class="select2-selection__rendered" id="select2-_pf_459940_4811-container" role="textbox"
                            aria-readonly="true" [title]="selectedCountry">{{selectedCountry}}</span>
                        <span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>
                    </span>
                </span>
                <span class="dropdown-wrapper" aria-hidden="true"></span>
            </span>
            <span *ngIf="country.invalid && (country.dirty || country.touched)" class="pf_error">
                {{content.text9.error}}</span>
        </div>
        <div *ngIf="isRelPointLab" [attr.data-sort]="relPointLabOrder" id="_pf_61716_7266" class="pf_field pf_field--radios pf-required"
            [ngClass]="relPointLab.invalid && (relPointLab.dirty || relPointLab.touched)? 'pf_has_errors block-'+relPointLabOrder:'block-'+relPointLabOrder"
            data-validate="radio">
            <legend for="_pf_61716_7266">{{relPointLabLabel}}
            </legend><span class="pf-required--symbol"> * </span>
            <ul>
                <li>
                    <input type="radio" id="relPointLab1" name="relPointLab" ngModel required #relPointLab="ngModel"
                    [value]="content.radio1.item.item1">
                    <label for="relPointLab1">{{content.radio1.item.item1}}</label>
                </li>
                <li>
                    <input type="radio" id="relPointLab2" name="relPointLab" ngModel required #relPointLab="ngModel"
                    [value]="content.radio1.item.item2">
                    <label for="relPointLab2">{{content.radio1.item.item2}}</label>
                </li>
                <li>
                    <input type="radio" id="relPointLab3" name="relPointLab" ngModel required #relPointLab="ngModel"
                    [value]="content.radio1.item.item3">
                    <label for="relPointLab3">{{content.radio1.item.item3}}</label>
                </li>
            </ul>
            <span *ngIf="relPointLab.invalid && (relPointLab.dirty || relPointLab.touched)" class="pf_error">
                {{content.radio1.error}}</span>
        </div>
        <div *ngIf="isRfp_Related" [attr.data-sort]="rfp_RelatedOrder" id="_pf_61716_3971" class="pf_field pf_field--radios pf-required"
            [ngClass]="rfp_Related.invalid && (rfp_Related.dirty || rfp_Related.touched)? 'pf_has_errors block-'+rfp_RelatedOrder:'block-'+rfp_RelatedOrder"
            data-validate="radio">
            <legend for="_pf_61716_3971">{{rfp_RelatedLabel}}
            </legend>
            <span class="pf-required--symbol"> * </span>
            <ul>
                <li>
                    <input type="radio" id="rfp_Related_0" name="rfp_Related" ngModel required #rfp_Related="ngModel"
                    [value]="content.radio2.item1">
                    <label for="rfp_Related_0">{{content.radio2.item1}}</label>
                </li>
                <li>
                    <input type="radio" id="rfp_Related_1" name="rfp_Related" ngModel required #rfp_Related="ngModel"
                    [value]="content.radio2.item2">
                    <label for="rfp_Related_1">{{content.radio2.item2}}</label>
                </li>
            </ul>
            <span *ngIf="rfp_Related.invalid && (rfp_Related.dirty || rfp_Related.touched)" class="pf_error">
                {{content.radio2.error}}</span>
        </div>
        <div *ngIf="isNewsletter" [attr.data-sort]="newsletterOrder" [ngClass]="'block-'+newsletterOrder" id="_pf_61716_873" class="pf_field pf_field--checkboxes " data-validate="checkboxes">
            <legend for="_pf_61716_873">{{newsletterLabel}}
            </legend>
            <ul>
                <li>
                    <input type="checkbox" class="pf_field__input empty" id="newsletter" name="newsletter" ngModel
                        #newsletter="ngModel" [value]="false">
                    <label for="newsletter">{{content.checkbox1.item1}}</label>
                </li>
            </ul>
        </div>
        <div *ngIf="isAreaInterest" [attr.data-sort]="areaInterestOrder" id="_pf_61716_873" class="pf_field pf_field--checkboxes "
            [ngClass]="!isCheckedArea() && isFormSubmit? 'pf_has_errors block-'+areaInterestOrder:'block-'+areaInterestOrder" data-validate="checkboxes">
            <legend for="_pf_61716_873">{{areaInterestLabel}}
            </legend>&nbsp;<span class="pf-required--symbol"> * </span>
            <ul>
                <li *ngFor="let c of areaOfInterest; let i = index" (change)="onSelectArea($event)">
                    <input type="checkbox" class="pf_field__input empty" id="areaInterest_{{i}}"
                        name="areaInterest" ngModel #areaInterest="ngModel" value="yes">
                    <label for="areaInterest_{{i}}">{{c.name}}</label>
                </li>
            </ul>
            <span *ngIf="!isCheckedArea() && isFormSubmit" class="pf_error">
                {{content.text12.error}}</span>
        </div>
        <div *ngIf="isMessage" [attr.data-sort]="messageOrder" class="pf_field pf_field--textarea pf-required"
            [ngClass]="message.invalid && (message.dirty || message.touched)? 'pf_has_errors block-'+messageOrder:'block-'+messageOrder" data-validate="text">
            <label for="_pf_61716_6848">{{messageLabel}}
                &nbsp;<span class="pf-required--symbol"> *
                </span> </label>
            <textarea class="pf_field__input empty" id="message" name="message" ngModel required
                #message="ngModel"></textarea>
            <span *ngIf="message.invalid && (message.dirty || message.touched)" class="pf_error">
                {{content.text10.error}}</span>
        </div>
        <div *ngIf="isConsent" [attr.data-sort]="consentOrder" class="pf_field pf_field--consent pf-required"
            [ngClass]="consent.invalid && (consent.dirty || consent.touched)? 'pf_has_errors block-'+consentOrder:'block-'+consentOrder"
            data-validate="checkboxes">
            <div class="pf_consent__wrapper">
                <input type="checkbox" id="consent" name="consent" ngModel required #consent="ngModel" value="1">
                <label for="consent">{{consentLabel}}
                    <a href="/terms-of-use">
                        {{content.checkbox2.link}} </a>&nbsp;<span class="pf-required--symbol">*</span>
                </label>
                <span *ngIf="consent.invalid && (consent.dirty || consent.touched)" class="pf_error">
                    {{content.checkbox2.error}}</span>
            </div>
        </div>
        <div *ngIf="isRecaptcha" [attr.data-sort]="recaptchaOrder" [ngClass]="'block-'+recaptchaOrder"
            [ngClass]="captcha.invalid && (captcha.dirty || captcha.touched)? 'pf_has_errors block-'+recaptchaOrder:'block-'+recaptchaOrder"
            class="pf_field pf_field--recaptcha">
            <div>
                <re-captcha [(ngModel)]="declarativeFormCaptchaValue" name="captcha" required #captcha="ngModel"
                    (resolved)="addTokenLog('Declarative form mode resolved', $event)"></re-captcha>
            </div>
            <div *ngIf="captcha.invalid && (captcha.dirty || captcha.touched)" class="pf_error col-md-12">
                {{content.checkbox2.error}}</div>
        </div>
        <div class="block-18 pf_field pf_field--submit">
            <pls-button-container [class]="'btn-our-blue'" 
                [bodyText]="buttonLabel"
                (clickBtn)="onFormSubmit()" [isButton]="true"
                >
            </pls-button-container>
        </div>
        <input type="hidden" name="form_id" data-validate="number" class="pf_form__id" value="61716">
        <input type="hidden" name="form_title" class="pf_form__title" value="contact-us-1563284913">
        <input type="hidden" name="wp_rest_nonce" class="pf_form__wp_rest_nonce" value="f3d76eef20">
        <span *ngIf="isCountryDisplay" class="select2-container select2-container--default select2-container--open"
            style="position: absolute; top: 448px; left: 0px;width: 100%;">
            <span class="select2-dropdown select2-dropdown--below" dir="ltr" style="width: 100%;">
                <span class="select2-search select2-search--dropdown">
                    <input
                        class="select2-search__field" type="text" tabindex="0" autocomplete="off" autocorrect="off"
                        autocapitalize="off" spellcheck="false" role="combobox" aria-autocomplete="list" aria-expanded="true"
                        aria-owns="select2-_pf_459940_4811-results" placeholder="Search" (input)="onSearchChange($event)"
                        aria-activedescendant="select2-_pf_459940_4811-result-tdyq-AZ">
                        <i class="bi bi-search" aria-hidden="true"></i>
                </span>
                <span class="select2-results">
                    <ul class="select2-results__options" role="listbox" id="select2-_pf_459940_4811-results"
                        aria-expanded="true" aria-hidden="false" aria-activedescendant="select2-_pf_459940_4811-result-tdyq-AZ">
                        <li class="select2-results__option" role="option" data-selected="false"></li>
                        <li (mouseover)='over($event)' (mouseout)='out($event)' (click)="countryChange($event)"
                            class="select2-results__option" id="select2-" role="option" data-selected="false"
                            *ngFor="let c of countries" [value]="c.value">{{c.name}}</li>
                    </ul>
                </span>
            </span>
        </span>
    </form>
</div>