<!--header start -->
<header>
  <div class="container border-bottom d-flex header-top">
    <div>
      <a href=""><img src="assets/images/logo1.png" alt="Point Lab" class="img">
        <img class="logo" src="assets/images/logo-w2.png" alt="Point Lab" class="img"></a>
    </div>
    <div class="d-flex">
      <div>
        <select class="language-dropdown" #selectedLang (change)="switchLang(selectedLang.value)">
          <option *ngFor="let language of translate.getLangs()" [value]="language"
            [selected]="language === translate.currentLang">
            <span *ngIf="language=='en'">{{ 'English' }}</span>
            <span *ngIf="language=='fr'">{{ 'French' }}</span>
          </option>
        </select>
      </div>
      <div class="divSearch">
        <a routerLink="/search" class="bi bi-search searchLink">{{content.Search.title}}</a>
      </div>
    </div>
  </div>

</header>
<!--header end -->

<!--nav bar start-->
<nav>
  <div class="navbar container">
    <i class='bx bx-menu' (click)="openMenu(true)"></i>
    <div class="nav-links">
      <div class="sidebar-logo">
        <span class="logo-name"></span>
        <i class='bx bx-x' (click)="openMenu(false)"></i>
      </div>
      <ul class="links">
        <!--industries start-->
        <li>
          <a routerLink="/industries" routerLinkActive="active-link">{{content.Industries.title}}</a>
          <i class='bx bxs-chevron-down htmlcss-arrow arrow' id="i_industries"
            (click)="openSubMenuLevel1('industries')"></i>
          <ul class="htmlCss-sub-menu sub-menu" id="industries">
            <li><a routerLink="/industries/banking-and-capital-market">{{content.Industries.Industries.bankingCapitalMarkets}}</a></li>
            <li><a routerLink="/industries/insurance">{{content.Industries.Industries.insurance}}</a></li>
            <li><a routerLink="/industries/retail">{{content.Industries.Industries.retail}}</a></li>
            <li><a routerLink="/industries/distributionWholesale">{{content.Industries.Industries.distributionWholesale}}</a></li>
            <li><a routerLink="/industries/telecoms">{{content.Industries.Industries.telecoms}}</a></li>
          </ul>
        </li>
          <!--industries end-->

          <!--services & its sub start-->
        <li>
          <a routerLink="/services" routerLinkActive="active-link">{{content.Services.title}}</a>
          <i class='bx bxs-chevron-down htmlcss-arrow arrow  ' id="i_servicesSubMenu"
            (click)="openSubMenuLevel1('servicesSubMenu')"></i>
          <ul class="htmlCss-sub-menu sub-menu" id="servicesSubMenu">
            <!-- technologySolutions & sub start-->
            <li class="more">
                <a routerLink="/services/technology-solutions">{{ content.Services.services.technologySolutions.title }}</a>
                <i class='bx bxs-chevron-right arrow more-arrow' id="i_technology_solutions_menu_submenu"
                  (click)="openSubMenuLevel2('i_technology_solutions_menu_submenu')"></i>
              <ul class="more-sub-menu sub-menu" id="i_technology_solutions_menu_submenu">
                <li><a routerLink="/services/technology-solutions/intelligent-automation">{{ content.Services.services.technologySolutions.intelligentAutomation }}</a></li>
                <li><a routerLink="/services/technology-solutions/admnext">{{content.Services.services.technologySolutions.applDevMaintenanceServices }}</a></li>
                <li><a routerLink="/services/technology-solutions/testing-services">{{content.Services.services.technologySolutions.testingServices }}</a></li>
              </ul>
            </li>
            <!-- technologySolutions & sub end-->
            <!-- Intelligent_Industry & sub start-->
            <li class="more">
                <a routerLink="/services/intelligent-industry">{{content.Services.services.intelligentIndustry.title }}</a>
                <i class='bx bxs-chevron-right arrow more-arrow' id="i_intelligent_industry_menu_submenu"
                  (click)="openSubMenuLevel2('intelligent_industry_menu_submenu')"></i>
              <ul class="more-sub-menu sub-menu" id="intelligent_industry_menu_submenu">
                <li><a routerLink="/services/intelligent-industry/data-driven-rd-for-life-sciences">{{content.Services.services.intelligentIndustry.datadriveninlifesciences }}</a></li>
                <li><a routerLink="/services/intelligent-industry/driving-automation-systems-validation">{{content.Services.services.intelligentIndustry.drivingAutomationSystemsValidation }}</a></li>
              </ul>
            </li>
            <!-- Intelligent_Industry & sub end-->
            <!-- Business_Operations & sub start-->
            <li class="more">
                <a routerLink="/services/business-services">{{content.Services.services.businessOperations.mainPage.title}}</a>
                <i class='bx bxs-chevron-right arrow more-arrow' id="i_business_operations_menu_submenu"
                  (click)="openSubMenuLevel2('business_operations_menu_submenu')"></i>
              <ul class="more-sub-menu sub-menu" id="business_operations_menu_submenu">
                <li><a routerLink="/services/business-services/transform-your-finance-operations">{{content.Services.services.businessOperations.mainPage.transformYourFinanceOperations}}</a></li>
                <li><a routerLink="/services/business-services/optimize-your-supply-chain-and-vendor-performance">{{content.Services.services.businessOperations.mainPage.optimizeyourSupplyChain}}</a></li>
                <li><a routerLink="/services/business-services/retain-and-engage-employees">{{content.Services.services.businessOperations.mainPage.retainEngageemployees}}</a></li>
                <li><a routerLink="/services/business-services/gain-more-insights-from-business-analytics">{{content.Services.services.businessOperations.mainPage.gainmoreinsightsfromyourbusinessanalytics}}</a></li>
                <li><a routerLink="/services/business-services/enrich-your-customer-interactions">{{content.Services.services.businessOperations.mainPage.enrichyourcustomerinteractions}}</a></li>
              </ul>
            </li>
            <!-- Business_Operations & sub end-->
          </ul>
        </li>
        <!--services & its sub end-->
        <!-- Partners & sub start-->
        <li>
          <a routerLink="/global-technology-partners" routerLinkActive="active-link">{{content.Partners.title}}</a>
        </li>
        <!-- Partners & sub end -->

        <!-- InsightsNews start-->
        <li>
          <a routerLink="/">{{content.InsightsNews.title}}</a>
        </li>
        <!-- InsightsNews end-->
        <!-- About_us start-->
        <li>
          <a routerLinkActive="active-link" routerLink="/about-us">{{content.Aboutus.title}}</a>
          <i class='bx bxs-chevron-down htmlcss-arrow arrow ' id="i_aboutUsSubMenu"
            (click)="openSubMenuLevel1('aboutUsSubMenu')"></i>
          <ul class="htmlCss-sub-menu sub-menu" id="aboutUsSubMenu">
            <li><a routerLink="/about-us/contact-us">{{content.Aboutus.ContactUs.title}}</a></li>
          </ul>
        </li>
        <!-- About_us end-->
        <!-- Careers start-->
        <li>
          <a routerLink="/careers">{{content.Careers.title}}</a>
        </li>
        <!-- Careers end -->
      </ul>
    </div>

  </div>
</nav>
<!--nav bar end-->