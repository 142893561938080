import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/services/common/common.service';

@Component({
  selector: 'pl-all-stories',
  templateUrl: './all-stories.component.html',
  styleUrls: ['./all-stories.component.scss']
})
export class AllStoriesComponent implements OnInit {

  content: any;
  boxs: any = [];
  startIndex = 0;
  currentIndex = 0;
  noOfRecords = 2;
  noOfRecordByPage = 2;
  totalpages = 0;
  pageNumber = 'Page 1 of 2';
  listOfPage = [
    { page: this.noOfRecords }
  ];

  constructor(
    public translate: TranslateService,
    private router: Router,
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    let storedPage = this.commonService.getPageName();
    if (storedPage == null) {
      storedPage = 'employeeStories';
    }
    this.content = this.translate.instant(storedPage);
    this.setBoxData();
  }

  setBoxData() {
    let totalRecords = this.content.all.boxs.length;
    var totalPages=totalRecords / this.noOfRecords;
    if ((totalRecords % this.noOfRecords) > 0) {
      totalPages = totalPages + 1;
    }
    this.totalpages = (Math.floor(totalRecords / this.noOfRecordByPage));
    if ((totalRecords % this.noOfRecordByPage) > 0) {
      this.totalpages = this.totalpages + 1;
    }

    this.pageNumber = 'Page ' + (this.currentIndex + 1) + ' of ' + this.totalpages.toString();
    this.listOfPage = [];
    for (let i = 0; i < totalPages; i++) {
      this.listOfPage.push({ page: this.noOfRecords * (i + 1) });
    }

    let that = this;
    that.boxs = [];
    this.content.all.boxs.forEach(function (element, index) {
      if (index >= (that.noOfRecordByPage * that.currentIndex) && index < (that.noOfRecordByPage * (that.currentIndex + 1))) {
        that.boxs.push(element);
      }
    });
  }

  showPage(pageNumber) {
    this.currentIndex = 0;
    this.noOfRecordByPage = pageNumber;
    this.setBoxData();
  }

  clickBtn(index) {
    this.currentIndex = index;
    if (this.currentIndex < 0) {
      this.router.navigateByUrl(this.getBackUrl());
    } else {
      this.setBoxData();
    }
  }

  getBackUrl(): string {
    let backUrl = this.commonService.getbackUrl();
    if (backUrl == null) {
      backUrl = this.content.all.backUrl;
    }
    return backUrl || '/';
  }
}
