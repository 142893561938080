import { Component, ElementRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AREA_OF_INTEREST, COUNTRY, INDUSTRIES, keyValueOfMaster, POSITION_LEVEL } from '../../constants';
import { AuthService } from '../../services/auth/auth.service';
import { CommonService } from '../../services/common/common.service';
import { MailDetails } from './mail-details';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'pls-contact-us',
  templateUrl: './pls-contact-us.component.html',
  styleUrls: ['./pls-contact-us.component.scss']
})
export class PlsContactUsComponent implements OnInit {

  @Input() isName = false;
  @Input() isFName = false;
  @Input() isLName = false;
  @Input() isJobTitle = false;
  @Input() isEmail = false;
  @Input() isResearch = false;
  @Input() isPhone = false;
  @Input() isCompany = false;
  @Input() isIndustry = false;
  @Input() isPosition = false;
  @Input() isCountry = false;
  @Input() isRelPointLab = false;
  @Input() isRfp_Related = false;
  @Input() isNewsletter = false;
  @Input() isAreaInterest = false;
  @Input() isMessage = false;
  @Input() isConsent = false;
  @Input() isRecaptcha = false;
  @Input() isSendMail = true;


  @Input() nameLabel = '';
  @Input() fNameLabel = '';
  @Input() lNameLabel = '';
  @Input() jobTitleLabel = '';
  @Input() emailLabel = '';
  @Input() researchLabel = '';
  @Input() phoneLabel = '';
  @Input() companyLabel = '';
  @Input() industryLabel = '';
  @Input() positionLabel = '';
  @Input() countryLabel = '';
  @Input() relPointLabLabel = '';
  @Input() rfp_RelatedLabel = '';
  @Input() newsletterLabel = '';
  @Input() areaInterestLabel = '';
  @Input() messageLabel = '';
  @Input() consentLabel = '';
  @Input() recaptchaLabel = '';
  @Input() selectedCountry = '';
  @Input() buttonLabel = '';

  @Input() nameOrder = '1';
  @Input() fNameOrder = '1';
  @Input() lNameOrder = '2';
  @Input() jobTitleOrder = '3';
  @Input() emailOrder = '4';
  @Input() researchOrder = '5';
  @Input() phoneOrder = '6';
  @Input() companyOrder = '7';
  @Input() industryOrder = '8';
  @Input() positionOrder = '9';
  @Input() countryOrder = '10';
  @Input() relPointLabOrder = '11';
  @Input() rfp_RelatedOrder = '12';
  @Input() newsletterOrder = '13';
  @Input() areaInterestOrder = '14';
  @Input() messageOrder = '15';
  @Input() consentOrder = '16';
  @Input() recaptchaOrder = '17';

  content: any;
  @ViewChild('f') form!: FormGroup;
  industries = INDUSTRIES;
  position_Level = POSITION_LEVEL;
  countriesAll = COUNTRY;
  areaOfInterest = AREA_OF_INTEREST;
  selectedArea = [{} as keyValueOfMaster];
  isFormSubmit = false;
  successMsg = '';
  errorMsg = '';
  isCountryDisplay = false;
  highlighted = '';
  countries = this.countriesAll;
  isLoggedIn = false;
  mailDetails;
  log: string[] = [];
  declarativeFormCaptchaValue?: string;

  constructor(
    public translate: TranslateService,
    private el: ElementRef,
    public router: Router,
    public commonService: CommonService,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: any
  ) { }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.el.nativeElement.contains(event.target)) {
      //this.countryDropdown();
    } else {
      this.isCountryDisplay = false;
      this.countries = this.countriesAll;
    }
  }

  ngOnInit(): void {
    this.content = this.translate.instant('contactUs.contactUsForm');
    this.selectedArea.splice(0, 1);
    this.setLabelValue();
  }

  setLabelValue() {
    this.nameLabel = this.nameLabel == '' ? this.content.text.title : this.nameLabel;
    this.fNameLabel = this.fNameLabel == '' ? this.content.text1.title : this.fNameLabel;
    this.lNameLabel = this.lNameLabel == '' ? this.content.text2.title : this.lNameLabel;
    this.jobTitleLabel = this.jobTitleLabel == '' ? this.content.text3.title : this.jobTitleLabel;
    this.emailLabel = this.emailLabel == '' ? this.content.text4.title : this.emailLabel;
    this.phoneLabel = this.phoneLabel == '' ? this.content.text5.title : this.phoneLabel;
    this.companyLabel = this.companyLabel == '' ? this.content.text6.title : this.companyLabel;
    this.industryLabel = this.industryLabel == '' ? this.content.text7.title : this.industryLabel;
    this.positionLabel = this.positionLabel == '' ? this.content.text8.title : this.positionLabel;
    this.countryLabel = this.countryLabel == '' ? this.content.text9.title : this.countryLabel;
    this.messageLabel = this.messageLabel == '' ? this.content.text10.title : this.messageLabel;
    this.researchLabel = this.researchLabel == '' ? this.content.text11.title : this.researchLabel;
    this.areaInterestLabel = this.areaInterestLabel == '' ? this.content.text12.title : this.areaInterestLabel;
    this.relPointLabLabel = this.relPointLabLabel == '' ? this.content.radio1.title : this.relPointLabLabel;
    this.rfp_RelatedLabel = this.rfp_RelatedLabel == '' ? this.content.radio2.title : this.rfp_RelatedLabel;
    this.newsletterLabel = this.newsletterLabel == '' ? this.content.checkbox1.title : this.newsletterLabel;
    this.consentLabel = this.consentLabel == '' ? this.content.checkbox2.content : this.consentLabel;
    this.buttonLabel = this.buttonLabel == '' ? this.content.buttonContainer.bodyText : this.buttonLabel;
  }

  onFormSubmit() {
    this.successMsg = '';
    this.errorMsg = '';
    this.isFormSubmit = true;
    this.commonService.validateAllFields(this.form, this.el.nativeElement);
    if (this.form.valid) {
      if (this.isSendMail) {
        this.sendMail();
        this.form.reset();
        this.successMsg = this.content.successMsg;
      }
    }
  }

  sendMail() {    
    this.mailDetails = new MailDetails();
    const messages = this.getMessage();
    let subject = 'PS_Contactus from ' + this.router.url.split('/')[1];
    
    this.mailDetails.body = messages;
    this.mailDetails.destination = environment.emailTo;
    this.mailDetails.subject = subject;    

    this.authService.sendEmail(this.mailDetails).subscribe({
      next: data => {
        this.successMsg = this.content.successMsg;
      }
    });
  }

  getMessage() {
    let name = this.form.controls.Name == undefined ? this.form.controls.fName.value + ' ' + this.form.controls.lName.value : this.form.controls.Name.value;
    let messages = this.content.dear + ' ' + name + '<br><br>'
    this.mailDetails.name = name;

    if (this.form.controls.jobTitle) {
      messages = messages + '<br>' + this.jobTitleLabel + ' : ' + this.form.controls.jobTitle.value;
      this.mailDetails.JobTitle = this.form.controls.jobTitle.value;
    }
    if (this.form.controls.email) {
      messages = messages + '<br>' + this.emailLabel + ' : ' + this.form.controls.email.value;
      this.mailDetails.Email = this.form.controls.email.value;
    }
    if (this.form.controls.research) {
      messages = messages + '<br>' + this.researchLabel + ' : ' + this.form.controls.research.value;
      this.mailDetails.Research = this.form.controls.research.value;
    }
    if (this.form.controls.tel) {
      messages = messages + '<br>' + this.phoneLabel + ' : ' + this.form.controls.tel.value;
      this.mailDetails.Phone = this.form.controls.tel.value;
    }
    if (this.form.controls.company) {
      messages = messages + '<br>' + this.companyLabel + ' : ' + this.form.controls.company.value;
      this.mailDetails.Company = this.form.controls.company.value;
    }
    if (this.form.controls.industry) {
      messages = messages + '<br>' + this.industryLabel + ' : ' + this.form.controls.industry.value;
      this.mailDetails.Industry = this.form.controls.industry.value;
    }
    if (this.form.controls.position) {
      messages = messages + '<br>' + this.positionLabel + ' : ' + this.form.controls.position.value;
      this.mailDetails.Position = this.form.controls.position.value;
    }
    if (this.form.controls.country) {
      messages = messages + '<br>' + this.countryLabel + ' : ' + this.form.controls.country.value;
      this.mailDetails.Country = this.form.controls.country.value;
    }
    if (this.form.controls.relPointLab) {
      messages = messages + '<br>' + this.relPointLabLabel + ' : ' + this.form.controls.relPointLab.value;
      this.mailDetails.Relationship = this.form.controls.relPointLab.value;
    }
    if (this.form.controls.rfp_Related) {
      messages = messages + '<br>' + this.rfp_RelatedLabel + ' : ' + this.form.controls.rfp_Related.value;
      this.mailDetails.RFI_RFP = this.form.controls.rfp_Related.value;
    }
    if (this.form.controls.newsletter) {
      messages = messages + '<br>' + this.newsletterLabel + ' : ' + this.form.controls.newsletter.value;
      this.mailDetails.Newsletter = this.form.controls.newsletter.value;
    }
    if (this.form.controls.areaInterest) {
      let areaOfInterest = this.getSelectedAreaOfInterest();
      messages = messages + '<br>' + this.areaInterestLabel + ' : ' + areaOfInterest;
      this.mailDetails.AreaOfInterest = areaOfInterest;
    }
    if (this.form.controls.message) {
      messages = messages + '<br>' + this.messageLabel + ' : ' + this.form.controls.message.value;
      this.mailDetails.Message = this.form.controls.message.value;
    }
    
    messages = messages + '<br><br>' + this.content.mailbody + '<a href="' + this.document.location.href + '">' + this.document.location.href + '</a>';
    messages = messages + '<br><br>Thanks<br>';
    return messages;
  }

  getSelectedAreaOfInterest() {
    let selectedAreaOfInteres = '';
    let that = this;
    this.selectedArea.forEach(function (v) {
      selectedAreaOfInteres += ',' + that.areaOfInterest[v.name.replace('areaInterest_', '')].value;
    });

    return selectedAreaOfInteres = selectedAreaOfInteres.substring(1);
  }

  onSelectArea(event: Event) {
    this.isFormSubmit = true;
    const item = (<HTMLInputElement>event.target);
    const sIndex = this.selectedArea.findIndex(x => x.name === item.id);
    if (item.checked && sIndex < 0) {
      this.selectedArea.push({ name: item.id, value: 'true' });
    } else if (sIndex >= 0) {
      this.selectedArea.splice(sIndex, 1);
    }
  }

  isCheckedArea() {
    return this.selectedArea.length > 0 ? true : false;
  }

  countryDropdown() {
    this.isCountryDisplay = !this.isCountryDisplay;
  }

  over(event: Event) {
    const item = (<HTMLInputElement>event.target);
    item.classList.add("select2-results__option--highlighted");
    item.setAttribute('data-selected', 'true');
  }

  out(event: Event) {
    const item = (<HTMLInputElement>event.target);
    item.classList.remove("select2-results__option--highlighted");
    item.setAttribute('data-selected', 'false');
  }

  countryChange(event: Event) {
    const item = (<HTMLInputElement>event.target);
    this.selectedCountry = item.textContent || '';
    this.isCountryDisplay = false;
    this.countries = this.countriesAll;
  }

  onSearchChange(event: Event): void {
    const item = (<HTMLInputElement>event.target);
    this.countries = this.countriesAll.filter(c => Object.keys(c).some(k => c[k].toLowerCase().includes(item.value.toLowerCase())));
  }

  addTokenLog(message: string, token: string | null) {
    this.log.push(`${message}: ${this.formatToken(token)}`);
  }

  formatToken(token: string | null) {
    return token !== null
      ? `${token.substr(0, 7)}...${token.substr(-7)}`
      : 'null';
  }
  public resolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }
}
