<div class="logos_and_quotes__quote" [ngClass]="class">
    <h3 class="logos_and_quotes__quote__title">{{title}}</h3>
    <div class="logos_and_quotes__quote__content">
        <ul>
            <li class="logos_and_quotes__quote__icon" *ngFor="let b of boxs">
                <i class="icon-materialicons-regular-1 bi bi-check-circle"></i>
                <span>{{b.value}}</span>
            </li>
        </ul>
    </div>
</div>
