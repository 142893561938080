import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from './app.enum';
import { CommonService } from './shared/services/common/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'pointlab-services';
  private currentLang: string = LANGUAGES.EN;

  constructor(
    private titleService: Title,
    private router: Router,
    private activePage: ActivatedRoute,
    private translateService: TranslateService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.changeTitle();
    this.setLanguage();
  }

  setLanguage(){
    let urlStrParams = window.location.search.substr(0, window.location.search.length);
    urlStrParams = urlStrParams.substr(1);
    if (urlStrParams === '' || urlStrParams == null) {
      const splitValues = window.location.toString().split('?');
      if (splitValues.length > 1) {
        urlStrParams = splitValues[1];
      }
    }
    const paramArr = urlStrParams.split('&');
    const lang = this.extractParamFromURL(paramArr, /^LANG=/i, LANGUAGES.EN, true);
    this.currentLang = LANGUAGES[lang.toUpperCase()];

    //Set Language
    try {
      this.translateService.use(this.currentLang);
      this.commonService.setLanguage(this.currentLang);
    } catch (e) {
      this.translateService.use(this.translateService.currentLang);
    }
    this.translateService.onLangChange.subscribe(()=>{
      this.changeTitle();
    })
  }

  changeTitle() {
    this.router.events.subscribe(event => {
      switch (true) {
        case event instanceof NavigationEnd:
          this.titleService.setTitle(this.activePage.firstChild?.snapshot.data.title);
          break;
        default:
          break;
      }
    });
  }

  extractParamFromURL(paramsArr, regex: RegExp, defaultVal, isCheckingLang = false) {
    const storedLang = this.commonService.getLanguage();
    if (paramsArr.filter(elem => regex.test(elem)).length > 0) {
      return paramsArr.filter(elem => regex.test(elem))[0].split('=')[1];
    }

    if (isCheckingLang) {
      return storedLang ? storedLang : defaultVal;
    }
  }

}

